export default {
    list: {
        title: "user_history",
        icone: "history",
        class: "flex-1 mt-1 px-3 items-center",
        name: 'userHistories',
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: false,
                name: "Actions",
                buttons: [{
                    text: "",
                    icon: "",
                    link: '',
                    color: 'primary'
                }],
            }
        },
        columns: [
            {
                name: "date",
                elements: [{
                    items: [{
                        class: "",
                        name: "date",
                        type: "text",
                    }, ],
                }, ]
            },
            {
                name: "user",
                elements: [{
                    items: [{
                        class: "ouka",
                        name: "user",
                        elements: [{
                            items: [{
                                    name: "fullName",
                                    icone: "person"
                                },
                                {
                                    name: "type",
                                    class: "font-light text-gray-700",
                                }
                            ]
                        }]

                    }, ],
                }, ]
            },
            {
                name: "IP address",
                elements: [{
                    items: [{
                        class: "",
                        name: "ip",
                        type: "text",
                    }, ],
                }, ]
            },
            {
                name: "model",
                elements: [{
                    items: [{
                        class: "ouka",
                        name: "model",
                        elements: [{
                            items: [{
                                    name: "name",
                                    icone: "contacts"
                                },
                                {
                                    name: "action",
                                    icone:"warning",
                                    class: "font-bold text-gray-700",
                                },
                                {
                                    name: "info",
                                    class: "font-bold text-gray-700 normal-case",
                                }
                            ]
                        }]

                    }, ],
                }, ]
            },
            {
                name: "Old data",
                elements: [{
                    items: [{
                        class: "",
                        name: "oldObject",
                        type: "text",
                    }, ],
                }, ]
            },
            {
                name: "New data",
                elements: [{
                    items: [{
                        class: "",
                        name: "newObject",
                        type: "text",
                    }, ],
                }, ]
            },
            // {
            //     name: "Wharhouse name",
            //     elements: [{
            //         items: [{
            //             class: "",
            //             name: "name_warehouse",
            //             type: "text",
            //         }, ],
            //     }, ]
            // },
            
        ],
        actions: {
            name: "actions",
            width: "10px",
            class: "rounded-full flex justify-center items-center focus:outline-none w-8 h-8 mr-3 mt-1",
            elements: [{
                icone: "delete",
                color: "danger",
                role: true,
                key: 'delete',
                tooltip: "Delete",
            }],
        },
        filters: {
            name: 'products',
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "search_users"
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    }
                },
                champs: [
                    {
                        label: "user",
                        key: "user",
                        name: "fullName",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "Type",
                        key: "type",
                        name: "type",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "Model",
                        key: "search",
                        value: null,
                        placeholder: "Email user, id order, id payment, traking Number ...",
                        type: "text",
                        class: 'py-2 text-xs  px-2  outline-none block h-full w-full',
                    },
                    {
                        label: "date",
                        key: "date",
                        value: null,
                        placeholder: "Date",
                        type: "date",
                        class: "py-2 text-xs px-2 outline-none block h-full w-full"
                    },
                ],
            },
        },
    },
}