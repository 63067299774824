<template>
  <div class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 py-3 items-center">
    <div class="bg-white w-full rounded-lg shadow pb-20">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
        <div class="flex w-full">
          <button
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
            @click="RouterBack()"
          >
            <i class="material-icons">chevron_left</i>
          </button>
           <div class="text-xl font-bold text-green-700">{{$t('shipping_details')}}</div>
        </div>
      </div>
      <div id="detail">
        <div class="grid lg:grid-cols-2 gap-6">
          <div class="flex justify-between shadow w-full h-auto p-4 my-1 mx-2  rounded-lg border-gray-100">
            <div class="flex w-full">
              <span class="rounded-full h-12 w-12 bg-gray-100"><i class="material-icons p-3 text-green-400 ">list_alt</i></span>
              <div class="ml-5 w-full space-y-2">
                <div class="text-base mt-2 mb-2 font-semibold text-gray-700"> {{$t('detail')}}</div>
                <div class="text-sm text-gray-600">
                   {{$t('shipping_address')}}:
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{ formData.address }}
                  </span>
                </div>
                 <div v-if="formData.zone && formData.country!='MA'" class="text-sm text-gray-600">
                   {{$t('zone_shipping')}}:
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{ formData.zone.name }}
                  </span>
                </div>
                <div class="text-sm text-gray-600">
                    {{$t('shipping_date')}}:
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{ formData.date }} 
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!--******************** Customer information ********************-->
          <div class="flex justify-between shadow w-full h-auto p-4 my-1 mx-2  rounded-lg border-gray-100">
            <div class="flex w-full">
              <span class="rounded-full h-12 w-12 bg-gray-100">
                <i class="material-icons p-3 text-green-400 ">person</i>
              </span>
              <div class="ml-5 w-full">
                <div class="text-base mt-2 mb-2 font-semibold text-gray-700">
                  {{$t('customer_information')}}
                </div>
                <div class="text-sm font-light text-gray-700">
                  <i class="material-icons text-gray-400">person</i>
                  <span class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700" >
                    {{ formData.order.customer.fullName }}
                  </span>
                </div>
                <div class="text-sm font-light text-gray-700">
                  <i class="material-icons text-gray-400">room</i>
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{formData.order.customer.address }}
                  </span>
                </div>

                <div class="text-sm font-light text-gray-700">
                  <i class="material-icons text-gray-400">phone</i>
                  <span
                    class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
                  >
                    {{ formData.order.customer.phone }}
                  </span>
                </div>
              </div>
            </div>
          </div>
             <!--******************** Courier  information ********************-->
         
        </div>

        <div class="flex pr-5 pl-5 mt-6">
          <div class="flex justify-between w-full h-auto p-4 rounded-lg border-gray-100" >
            <div class="flex w-full">
              <span class="rounded-full h-12 w-12 bg-gray-100">
                <i class="material-icons p-3 text-green-400 ">local_mall</i>
              </span>
              <div class="ml-1 w-full p-2">
                <div class="text-base font-semibold text-gray-700">
                  {{$t('products_order')}}
                </div>
                <div class="w-full mt-4">
                  <table class="min-w-full leading-normal">
                    <thead>
                      <tr> <th class="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider">{{$t('image')}}</th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider">{{$t('product')}}</th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider">{{$t('quantity')}}</th>
                        <th class="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider">{{$t('price')}}</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(item, index) in formData.order.details"
                      :key="item._id"
                    >
                      <tr :class="index % 2 == 0 ? 'bg-gray-100' : ''">
                        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                          <span class="flex text-gray-700 capitalize whitespace-no-wrap"><span class="mt-1"><img :src="item.product.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="h-16 w-16 rounded-md mx-auto"></span></span>
                        </td>
                        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                          <span class="flex text-gray-700 capitalize whitespace-no-wrap"><span class="mt-1">{{ item.product.name }}</span></span>
                        </td>
                        <td
                          class="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                        >
                          <p class="text-gray-700 ml-6 whitespace-no-wrap">
                            <span class="font-semibold"
                              >{{ item.quantity }}
                            </span>
                          </p>
                        </td>
                        <td
                          class="px-5 py-2 border-b border-gray-200 bg-white text-xs"
                        >
                          <span
                            class="justify-center text-xs text-center pl-2 pr-2 py-1 rounded-full text-gray-700"
                          >
                            <span
                              class="text-gray-800 font-semibold text-xs ml-1"
                            >
                              {{ item.unitPrice }}
                            </span>
                           <sup class="uppercase">{{currentCurrency }}</sup>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="$f.getAccessPermission(currentUser, 'userHistories', 'read')"
          class="flex pr-5 pl-5 mt-6"
        >
          <div
            class="flex justify-between w-full h-auto p-4 rounded-lg border-gray-100"
          >
            <div class="flex w-full">
              <span class="rounded-full h-12 w-12 bg-gray-100">
                <i class="material-icons p-3 text-green-400">history</i>
              </span>
              <div class="ml-1 w-full p-2">
                <div class="text-base font-semibold text-gray-700">
                  Info History
                </div>

                <div class="w-full">
                  <div class="overflow-x-auto mt-4">
                    <div-table
                      :config="configListUserHistory"
                      :data="dataHistories"
                      :loading="loading"
                      :paginate="paginate"
                      :DivFilter="false"
                      :idWharhouse="idWharhouse"
                      :currentUser="currentUser"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div  v-if="$f.getAccessPermission(currentUser, 'shippingsHistories', 'read')"  class="flex pr-5 pl-5 mt-6">
          <div class="flex justify-between w-full h-auto p-4 rounded-lg border-gray-100" >
            <div class="flex w-full">
              <div class="ml-1 w-full p-2">
                <!--table shipping historie -->
                   <div-table :config="configList" :data="shippingsHistories" :idWharhouse="idWharhouse" :currentUser="currentUser" :loading="loading" :paginate="paginate"  @paginatation="paginatation"></div-table>
                <!--/ table shipping histories -->
              </div>  
            </div> 
          </div>
        </div>     
      </div>
       
    </div>
  </div>
</template>
<script>
import config from './configHistories'
import configUserHistory from '../users/confighistory';
export default {
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
   },
  data() {
    return {
      configList: config.list,
      configListUserHistory: configUserHistory.list,
      dataHistories: [],
      loading:false,
      shippingsHistories:[],
       formData: {
            order:{
                customer: {
                    fullName:""
                },
                seller:{}
            },
            zone:{},
            courier:{}   
      },
      show_modal: false,
      content: [],
      currentCurrency:"",
      //currentUser: {},
       paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
       },
    };
  },
  async mounted() {
     if (this.$route.params.id) {
        const editData = await this.$server.get("shippings", {
            id: this.$route.params.id,
        });
        this.formData = editData.content;
         this.formData.date = this.$moment(this.formData.date).format('yyyy-MM-DD');
        this.formData.order.date = this.$moment(this.formData.order.date).format('yyyy-MM-DD HH:mm');
        await this.getCurrencyBycontry( this.formData.country);
        if (this.$f.getAccessPermission(this.currentUser, 'userHistories', 'read')) await this.getUserHistory({ 'model.id': this.$route.params.id });
        if (this.currentUser.type === "Seller") {
         let userI = this.configList.columns.map(function (e) {return e.name;}).indexOf('User');
         if(userI!=-1)  this.configList.columns.splice(userI, 1); 
         let userI2 = this.configList.columns.map(function (e) {return e.name;}).indexOf('Ip Address');
         if(userI2!=-1)  this.configList.columns.splice(userI2, 1);              
     }
     }
    
   // await this.getUser();
    await this.getShippingHistories();
  },
  methods: {
    RouterBack: function() {
                this.$router.back();
    },
    async getCurrencyBycontry(country){
          const resW = await this.$server.get("warehouses", { country: country}); 
          this.currentCurrency=resW.content.currency;
    },
    async getUser() {
      const res = await this.$me;
      this.currentUser = res.content;
    },
    /*******************************************Modal (View) update Chapping address and Status Order ************************************/
    async getShippingHistories() {
      const filter = {
        shipping: this.$route.params.id,
      };
      const res = await this.$server.search("shippingsHistories", filter);
      if (res.content.results) {
        this.shippingsHistories = res.content.results;
        this.shippingsHistories.total = res.content.total;
        this.shippingsHistories.lastpage = res.content.last_page;
        this.shippingsHistories.per_page = res.content.per_page;
        this.shippingsHistories.currentpage = res.content.current_page;
      } else this.shippingsHistories = [];
    },
    async paginatation(direction){
      var page=0;
      if(direction == "next"){
         page=++this.paginate.currentpage;
      }else{
         page=--this.paginate.currentpage;
      }
       const filters = {
          page: page,
          limit: this.limit,
      };
      this.getShipping(filters);
    },
    async getUserHistory(filters) {
      this.loading = true;
      filters.limit = 10;
      const res = await this.$server.search('userHistories', filters);
      this.loading = false;
      if (res && res.content.results) {
        this.dataHistories = res.content.results;
        for (const i in this.dataHistories) {
          this.dataHistories[i].date = this.$moment(
            this.dataHistories[i].date,
          ).format('yyyy-MM-DD  HH:mm');
        }
      } else {
        this.dataHistories = [];
      }
    },
  },
};
</script>

<style>
/* animation for vue transition tag */
dialog[open] {
  animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}
dialog::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
}
@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
