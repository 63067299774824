export default {
    list: {
        title: "Shippings Histories",
        name: 'shippingsHistories',
        icone: "history",
        class: "flex-1 mt-1 px-3 items-center",
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: false,
                name: "Actions",
                buttons: [{
                    text: "",
                    icon: "",
                    link: '',
                    color: 'primary'
                }],
            }
        },
        columns: [{
                name: "Date",
                elements: [{
                    items: [{
                        class: "",
                        date: true,
                        name: "date",
                        type: "text",
                    }, ],
                }, ]
            },
            {
                name: "User",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "user",
                        elements: [{
                            items: [{
                                name: "fullName",
                                class: "font-bold",
                            }]
                        }]
                    }],
                }, ]
            },
            {
                name: "Ip Address",
                elements: [{
                    items: [{
                        class: "",
                        name: "ip",
                        type: "text",

                    }, ],
                }, ]
            },
            {
                name: "Comment",
                elements: [{
                    items: [{
                        class: "",
                        name: "comment",
                        type: "text",

                    }, ],
                }, ]
            },
            {
                name: "Status",
                class: "",
                elements: [{
                    items: [{
                        class: "border capitalize rounded-full text-gray-600 h-8 text-xs px-8 bg-white hover:border-gray-500 focus:outline-none appearance-none",
                        name: "status",
                        options: (item) => {
                            switch (item) {
                                case 'to prepare':
                                    return "color: rgb(233 147 19); border-color: rgb(233 147 19);"
                                    break;
                                    case 'outofstock':
                                        return "color: rgb(203 21 160); border-color: rgb(203 21 160);"
                                        break;
                                case 'prepared':
                                    return "color:rgb(8, 194, 226); border-color: rgb(8, 194, 226);"
                                    break;
                                case 'shipped':
                                    return "color:rgb(106 71 9); border-color: rgb(106 71 9);"
                                    break;
                                case 'delivered':
                                    return "color:rgb(58 217 18); border-color: rgb(58 217 18);"
                                    break;
                                case 'paid':
                                    return "color:rgb(194, 168, 27); border-color: rgb(194, 168, 27);"
                                    break;
                                case 'processed':
                                    return "color:rgb(215, 26, 230); border-color: rgb(215, 26, 230);"
                                    break;            
                                case 'cancelled':
                                    return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);"
                                    break;
                                    case 'refused':
                                        return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);"
                                        break;
                                case 'remind':
                                    return "color: rgb(227 129 210); border-color: rgb(227 129 210);"
                                    break;    
                                case 'unreachable':
                                    return "color: rgb(141 8 118); border-color: rgb(141 8 118);"
                                    break;
                                case 'pending return':
                                    return "color: rgb(227 129 210); border-color: rgb(227 129 210);"
                                    break;    
                                case 'return':
                                    return "color: rgb(141 8 118); border-color: rgb(141 8 118);"
                                    break;    
                            }
                        },
                    }],
                }, ]
            },
        ],
        actions: {
            name: "Actions",
            width: "10px",
            class: "justify-end",
            elements: [{
                icone: "delete",
                color: "danger",
                role: true,
                key: 'delete',
                tooltip: "Delete",
            }],
        },

    },
}